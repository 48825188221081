// 
// _theme.scss
// Use this to add custom theme styles
//

.media-backdrop,
.navbar.navbar-inverse ~ .modal-backdrop-menu {
  background-color: rgb(0 0 0 / 35%);
}

body:not(.darkmode) .img-placeholder {
  filter: hue-rotate(1deg) brightness(1.1) contrast(1.2);
}
